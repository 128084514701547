import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.scss";
import { useState } from "react";

const Contact = () => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        "service_5qahv9t",
        "template_veucx5v",
        form.current,
        "35IXyQCD0V6B1IoVz"
      );
    } catch (error) {
      console.log(error.text);
    }
    setFormSubmitted("Thank you! We will get back to you shortly. ");
  };
  return (
    <section className="Contact" id="contact">
      <div className="container">
        <h4>Contact Us</h4>
        <p className="form-submit-message">{formSubmitted}</p>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <label>
              <sup>*</sup>First Name
            </label>
            <input type="text" name="first_name" required minLength={1} />
          </div>
          <div className="form-group">
            <label>
              <sup>*</sup>Last Name
            </label>
            <input type="text" name="last_name" required minLength={1} />
          </div>
          <div className="form-group">
            <label>
              <sup>*</sup>Email
            </label>
            <input type="email" name="email_address" required />
          </div>
          <div className="form-group">
            <label>
              <sup>*</sup>Phone
            </label>
            <input type="tel" name="phone" required />
          </div>
          <div className="form-group">
            <label>
              <sup>*</sup>Message
            </label>
            <textarea name="description" rows={10} required />
          </div>
          <button type="submit">Request a Quote</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
