import React from "react";
import Michael from "../../assets/fire-fighter.jpg";
import CoreValues from "../CoreValues/CoreValues";
import CurveNegative from "../../assets/curveNegative.svg";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <>
      <section className="AboutUs" id="about">
        <div className="container">
          <img src={Michael} alt="CEO" className="michael" />
          <div className="title-div">
            <h4 className="heading">About Michael Margiotta</h4>
            <h6 className="subheading">Founder and CEO</h6>
          </div>
          <div className="description">
            <p>
              After graduating high school Michael joined the United States Air
              Force honorably serving for 6 Years. Having served in Iraq and
              Afghanistan following the 9/11 attack Michael decided to go back
              to school to become a full time Firefighter/Emt serving Cherokee
              County Fire & EMS for 15 proud years. Along the way Michael picked
              up a new trade which to him became more of an art form. A jack of
              all trades to new construction, repairs and remodels.
            </p>
            <p>For us, its someone on the job, to do the job.</p>
            <p>We want to thank you for the opportunity in advance.</p>
          </div>
        </div>
        <img className="divider" src={CurveNegative} alt="" />
      </section>
      <CoreValues />
    </>
  );
};

export default AboutUs;
