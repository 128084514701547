import React from "react";
import { BsInstagram } from "react-icons/bs";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container">
        <small>&copy; The Margiotta Group LLC &nbsp;</small>
        <a
          href="https://www.instagram.com/themargiottagroup/"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram className="icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
