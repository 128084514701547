import React from "react";
import "./Gallery.scss";

const Gallery = () => {
  return (
    <section className="Gallery" id="gallery">
      <div className="container">
        <h3 className="title">Explore Our Gallery</h3>
      </div>
    </section>
  );
};

export default Gallery;
