import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Burger from "../Burger/Burger";
import { VscArrowUp } from "react-icons/vsc";
import { Link } from "react-scroll";
import "./Navbar.scss";

const Navbar = () => {
  const [showIcon, setShowIcon] = useState(false);
  const links = useRef([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return handleLinkAnimation();
  });

  const handleScroll = () => {
    if (window.scrollY > 225 && window.innerWidth > 758) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  };

  const handleNavSlide = () => {
    setIsActive(!isActive);
  };

  const handleLinkAnimation = () => {
    links.current.forEach((link, index) => {
      if (isActive) {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7}s`;
      }
      if (link.style.animation && !isActive) {
        link.style.animation = "";
      }
    });
  };

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <header>
      <nav className="Navbar">
        <ul className={isActive ? "nav-active nav-links" : "nav-links"}>
          <li
            className="nav-link"
            ref={(element) => {
              links.current[0] = element;
            }}
          >
            <Link
              className="nav-item"
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={handleClick}
            >
              Home
            </Link>
          </li>
          <li
            className="nav-link"
            ref={(element) => {
              links.current[1] = element;
            }}
          >
            <Link
              className="nav-item"
              activeClass="active"
              to="gallery"
              spy={true}
              offset={10}
              smooth={true}
              duration={500}
              onClick={handleClick}
            >
              Gallery
            </Link>
          </li>
          <li
            className="nav-link"
            ref={(element) => {
              links.current[2] = element;
            }}
          >
            <Link
              className="nav-item"
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              offset={10}
              onClick={handleClick}
            >
              About Us
            </Link>
          </li>
          <li
            className="nav-link"
            ref={(element) => {
              links.current[3] = element;
            }}
          >
            <Link
              className="nav-item"
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              duration={500}
              onClick={handleClick}
            >
              Services
            </Link>
          </li>
          <li
            className="nav-link"
            ref={(element) => {
              links.current[4] = element;
            }}
          >
            <Link
              className="nav-item"
              activeClass="active"
              to="contact"
              spy={true}
              offset={5}
              smooth={true}
              duration={500}
              onClick={handleClick}
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <Burger active={isActive} navSlide={handleNavSlide} />
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          duration={500}
        >
          <div
            style={{ display: showIcon ? "block" : "none" }}
            className="nav-icon"
          >
            <VscArrowUp size="2rem" className="down-arrow" />
          </div>
        </Link>
      </nav>
    </header>
  );
};

export default Navbar;
