import React from "react";
import { AiFillFormatPainter, AiFillHome } from "react-icons/ai";
import { MdHouseSiding, MdOutlineDeck, MdKitchen } from "react-icons/md";
import { GiAutoRepair, GiGrass } from "react-icons/gi";
import { FaInstalod } from "react-icons/fa";
import { BsBuilding } from "react-icons/bs";
import FormDivider from "../../assets/form-divider.svg";
import "./Services.scss";

const Services = () => {
  return (
    <section className="Services" id="services">
      <div className="container">
        <h4 className="header">Our Services</h4>
        <div className="cards">
          <div className="card card-1">
            <div className="icon">
              <AiFillFormatPainter />
            </div>
            <h6 className="card-title">Interior and Exterior Paint</h6>
          </div>
          <div className="card card-2">
            <MdHouseSiding />
            <h6 className="card-title">Siding</h6>
          </div>
          <div className="card card-3">
            <div className="icon">
              <GiAutoRepair />
            </div>
            <h6 className="card-title">Repairs</h6>
          </div>
          <div className="card card-4">
            <div className="icon">
              <FaInstalod />
            </div>
            <h6 className="card-title">Installations</h6>
          </div>
          <div className="card card-5">
            <div className="icon">
              <MdOutlineDeck />
            </div>
            <h6 className="card-title">Decking</h6>
          </div>
          <div className="card card-6">
            <div className="icon">
              <AiFillHome />
            </div>
            <h6 className="card-title">Pressure Wash</h6>
          </div>
          <div className="card card-7">
            <div className="icon">
              <MdKitchen />
            </div>
            <h6 className="card-title">Bathroom & Kitchen Remodels</h6>
          </div>
          <div className="card card-8">
            <div className="icon">
              <GiGrass />
            </div>
            <h6 className="card-title">Trim Work</h6>
          </div>
          <div className="card card-9">
            <div className="icon">
              <BsBuilding />
            </div>
            <h6 className="card-title">Residential and Commercial</h6>
          </div>
        </div>
      </div>
      <img className="divider" src={FormDivider} alt="" />
    </section>
  );
};

export default Services;
