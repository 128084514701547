import React from "react";
import SplitNegative from "../../assets/splitNegative.svg";
import "./CoreValues.scss";

const CoreValues = () => {
  return (
    <div className="CoreValues">
      <div className="container">
        <h4 className="title">Our Core Values & Principles</h4>
        <div className="cards">
          <div className="card">
            <p>Quality</p>
          </div>
          <div className="card">
            <p>Pricing</p>
          </div>
          <div className="card">
            <p>Service</p>
          </div>
        </div>
      </div>
      <img className="divider" src={SplitNegative} alt="" />
    </div>
  );
};

export default CoreValues;
