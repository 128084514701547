import React from "react";
import "./Home.scss";

const Home = () => {
  return (
    <section className="Home" id="home">
      <div className="container">
        <h2 className="title">The Margiotta Group</h2>
        <p className="slogan">Someone on the job to do the job.</p>
      </div>
    </section>
  );
};

export default Home;
