import React from "react";
import "./Burger.scss";

const Burger = ({ navSlide, active }) => {
  const handleBackground = {
    background: active ? "#efefef" : "",
  };
  return (
    <div onClick={navSlide} className={`${active ? "toggle" : ""} Burger`}>
      <div className="line1" style={handleBackground}></div>
      <div className="line2" style={handleBackground}></div>
      <div className="line3" style={handleBackground}></div>
    </div>
  );
};

export default Burger;
